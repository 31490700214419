import React from 'react'

const Portfolio = () =>
{
  return (
    <>
      <section className="section-portfolio py-32">
        <div className="py-20 text-center">
          <h1 className="text-5xl">Portfolio</h1>
        </div>
        <div className="flex justify-evenly flex-col lg:flex-row">
          <div className="portfolio lg:w-1/3">
            <img src="images/lochouse-2.png" className="w-full" alt="" />
            <div className="portfolio-desc p-3">
              <h2 className="portfolio-title">lochouse</h2>
              <h3 className="mt-2">Interior design landing page</h3>
              <p className="mt-2">Built with: React, Bootstrap</p>
              <p className="mt-14 text-center"><a href="https://lochouse.netlify.app/">View Site</a> / <a href="https://github.com/nmr15/nmr-lochouse">View Github</a></p>
            </div>
          </div>
          <div className="portfolio lg:w-1/3 mt-10 lg:mt-0">
            <img src="images/cuisina-2.png" className="w-full" alt="" />
            <div className="portfolio-desc  p-3">
              <h2 className="portfolio-title">cuisina (Work in progress)</h2>
              <h3 className="mt-2">Restaurant website with online ordering</h3>
              <p className="mt-2">Features: Dynamic menu with categories, add to cart</p>
              <p className="mt-2">Built with: React, Bootstrap</p>
              <p className="mt-8 text-center"><a href="https://cuisina.netlify.app/">View Site</a> / <a href="https://github.com/nmr15/nmr-cuisina">View Github</a></p>
            </div>
          </div>
        </div>

        <div className="flex justify-evenly flex-col lg:flex-row mt-10">
          <div className="portfolio lg:w-1/3">
            <img src="images/realgrande.jpeg" className="w-full" alt="" />
            <div className="portfolio-desc  p-3">
              <h2 className="portfolio-title">Real Grande</h2>
              <h3 className="mt-2">Full stack real estate website</h3>
              <p className="mt-2">Features: Search for houses, leave feedback, login and signup</p>
              <p className="mt-2">Built with: React, Bootstrap, Nodejs, MongoDB</p>
              <p className="mt-8 text-center"><a href="https://realgrande-front.onrender.com/">View Site</a> / <a href="https://github.com/nmr15/realgrande-front">View Github</a></p>
            </div>
          </div>
          <div className="portfolio lg:w-1/3 mt-10 lg:mt-0">
            <img src="images/weather.jpeg" className="w-full" alt="" />
            <div className="portfolio-desc  p-3">
              <h2 className="portfolio-title">Weather App</h2>
              <h3 className="mt-2">Real-time current weather</h3>
              <p className="mt-2">Features: Search by city name, choose between Fahrenheit or Celsius</p>
              <p className="mt-2">Built with: Vite/React, TailwindCSS, SCSS</p>
              <p className="mt-8 text-center"><a href="https://nmr-weather.netlify.app/">View Site</a> / <a href="https://github.com/nmr15/nmr-weather">View Github</a></p>
            </div>
          </div>
        </div>

        <div className="flex justify-evenly flex-col lg:flex-row mt-10">
          <div className="portfolio lg:w-1/3">
            <img src="images/todo-app.jpeg" className="w-full" alt="" />
            <div className="portfolio-desc  p-3">
              <h2 className="portfolio-title">Todo App</h2>
              <h3 className="mt-2">Full stack todo app</h3>
              <p className="mt-2">Features: View, add, edit, and delete tasks</p>
              <p className="mt-2">Built with: Vite/React, SASS, Nodejs, MongoDB</p>
              <p className="mt-8 text-center"><a href="https://todo-app-ht0c.onrender.com/#">View Site</a> / <a href="https://github.com/nmr15/todo-frontend">View Github</a></p>
            </div>
          </div>
        </div>

        <div className="py-20 text-center">
          <h1 className=" text-5xl">Frontend Mentor Submissions</h1>
        </div>

        <div className="flex justify-evenly items-center flex-col lg:flex-row">
          <div className="portfolio w-3/4 lg:w-1/5">
            <img src="images/qrcode.png" className="w-full" alt="" />
            <div className="portfolio-desc  p-3">
              <h2 className="portfolio-title">QR Code card</h2>
              <p className="mt-2">Built with: HTML5, CSS3</p>
              <p className="mt-8 text-center"><a href="https://nmr-qr-code.netlify.app/">View Site</a> / <a href="https://github.com/nmr15/FEM-QR-code-component">View Github</a></p>
            </div>
          </div>
          <div className="portfolio w-3/4 lg:w-1/5 mt-10 lg:mt-0">
            <img src="images/blogpreview.png" className="w-full" alt="" />
            <div className="portfolio-desc  p-3">
              <h2 className="portfolio-title">Blog preview card</h2>
              <p className="mt-2">Built with: HTML5, CSS3</p>
              <p className="mt-8 text-center"><a href="https://nmr15.github.io/frontendmentor/blog-preview-card/">View Site</a> / <a href="https://github.com/nmr15/frontendmentor/tree/main/blog-preview-card">View Github</a></p>
            </div>
          </div>
        </div>

      </section>
    </>
  )
}

export default Portfolio