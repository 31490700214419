import MediaQuery from "react-responsive"
import { contacts } from "../data"

const Hero = () => 
{
  return (
    <>
      <section className="section-hero  pb-32">
        <MediaQuery minWidth={769}>
          <div className="flex justify-between mt-36">
            <div className="w-6/12">
              <h2 className="">Hello, I am</h2>
              <h1 className="mt-2 text-4xl lg:text-7xl ">Nathaniel Ravelo</h1>
              <p className="w-9/12 mt-3 mb-10">
                Seeking for an entry level position in web developing to utilize my coding skills
                in the creation of useful web applications that will contribute to the company
                and to my personal growth as a professional web developer.
              </p>
              <a href="/download/Nathaniel_Resume.pdf" className="mt-3 btn-download" download="Nathaniel_Resume">Download Resume</a>
              <div className="mt-10">
                {
                  contacts.map((contact) => (
                    <div className="flex items-center mb-3" key={contact}>
                      <img src={contact.img} className="icon-contact" alt={contact.alt} />
                      <p className="ml-4">{contact.text}</p>
                    </div>
                  ))
                }
              </div>
            </div>
            <div className="w-6/12 flex justify-center">
              <img src="/images/portfolio.jpg" className="w-2/3"  alt="" />
            </div>
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={768}>
          <div className="mt-5">
            <h2 className="">Hello, I am</h2>
            <h1 className="mt-2 text-4xl lg:text-7xl ">Nathaniel Ravelo</h1>
            <img src="/images/portfolio.jpg" className="w-8/12 block mx-auto my-4" alt="" />
            <p className="mt-3 mb-6">
              Seeking for an entry level position in web developing to utilize my coding skills
              in the creation of useful web applications that will contribute to the company
              and to my personal growth as a professional web developer.
            </p>
            <a href="/download/Nathaniel_Resume.pdf" className="mt-3 btn-download" download="Nathaniel_Resume">Download Resume</a>
            <div className="mt-6">
              {
                contacts.map((contact) => (
                  <div className="flex items-center mb-3" key={contact}>
                    <img src={contact.img} className="icon-contact" alt={contact.alt} />
                    <p className="ml-4">{contact.text}</p>
                  </div>
                ))
              }
            </div>
          </div>
        </MediaQuery>
      </section>
    </>
  )
}

export default Hero