export const navLinks = [
  {
    id: "about",
    title: "About",
  },
  {
    id: "skills",
    title: "Skills",
  },
  {
    id: "portfolio",
    title: "Portfolio",
  },
];

export const contacts = [
  {
    id: 1,
    img: "images/envelope-solid.svg",
    alt: "email",
    text: "niel.rav.15@gmail.com",
  },
  {
    id: 2,
    img: "images/mobile-alt-solid.svg",
    alt: "phone",
    text: "(909) 729-7158",
  },
  {
    id: 3,
    img: "images/github-brands-solid.svg",
    alt: "github",
    text: "github.com/nmr115",
  },
  {
    id: 4,
    img: "images/linkedin-in-brands-solid.svg",
    alt: "linkedin",
    text: "linkedin.com/in/nathaniel-ravelo-941948143",
  },
  {
    id: 5,
    img: "images/home-solid.svg",
    alt: "home",
    text: "Pomona, CA",
  },
];