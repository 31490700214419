import './App.scss';
import About from "./Components/About";
import Hero from "./Components/Hero";
import Navbar from "./Components/Navbar";
import Portfolio from "./Components/Portfolio";
import Skills from "./Components/Skills";

function App() 
{
  return (
    <div className="App">
      <div className="container">
        <Hero />
        {/* <Navbar />
        <About />
        <Skills /> */}
        <Portfolio /> 
      </div>
      
    </div>
  );
}

export default App;
